export default {
    name: "中文科幻数据库",
    main: "https://www.csfdb.cn",
    server: "https://www.csfdb.cn:3001",
    wechat: {
        appId: "wx0061916b6b54baf4"
    },
    map: {
        key: '3a3d7aabad3c5b34901cb96cb53ac8bf'
    },
    accessToken: 'fc1179ab-84ac-4168-87c7-b27220fd2813'

}
