let WorkPage = () => import(/* webpackChunkName: "Nebula" */'../components/WorkPage')
let MoviePage = () => import(/* webpackChunkName: "Nebula" */'../components/MoviePage')
let Layout = () => import(/* webpackChunkName: "Nebula" */'../components/layout/MainPageLayout')

let routes = [{
    name: 'HomePage',
    path: '/',
    component: Layout,
    children: [{
        name: 'WorkPage',
        path: '',
        component: WorkPage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query ? route.query.query : "",
                category: route.query.category ? route.query.category : "",
                foreign: route.query.foreign === 'true' || route.query.foreign === true,
            }
        },
    }, {
        name: 'MoviePage',
        path: 'movies',
        component: MoviePage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query ? route.query.query : "",
                category: route.query.category ? route.query.category : "",
            }
        },
    }]
}, {
    path: '*',
    redirect: '/'
}]

export default routes