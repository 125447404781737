import Vue from 'vue'
import App from './App.vue'
import router from './routers/index'
import ElementUI from 'element-ui'
import vueHeadful from 'vue-headful';
import config from './config'
import store from './store'
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';

import './assets/stylesheets/style.scss'

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component('vue-headful', vueHeadful);

Vue.config.silent = true;

new Vue({
    components: {
        App
    },
    store,
    router,
    render: h => h(App),
}).$mount('#app');
